@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler {
  border: none;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}

.error-actions {
  margin: 15px;
}

.particle {
  background-color: #232741;
  background-image: url('./assets/Moon.png');
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: 90% 10%;
  /* display: flex;
  vertical-align: bottom; */
  width:100%;
  height:100vh;
}

.center_all {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: 'Indie Flower', cursive;
  font-size: 100px;
  display: block;
}

#text_div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 10px;
}
